import {Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { SettingsService } from '../settings.service';
import { MatSlideToggle, MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OnesignalService } from '../onesignal.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [MatListModule, MatSlideToggleModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent {
  themeOptions: string[] = ['Light', 'Dark', 'System'];
  isDarkTheme = true;
  selectedTheme:string;

  constructor(private settingsService: SettingsService, private onesignalService:OnesignalService, private _snackBar: MatSnackBar){
    this.selectedTheme = settingsService.currentTheme;
  }

  ngOnInit():void {
    this.selectedTheme = this.settingsService.currentTheme;
  }

  setTheme(selected:string) {
    this.selectedTheme = selected;
    this.settingsService.updateTheme(selected.toLowerCase());
  }

  async onNotificationToogleClicked(event: MouseEvent, toggle: MatSlideToggle): Promise<void> {
    event.preventDefault();
    event.stopImmediatePropagation();
    toggle.checked = !toggle.checked;
    if (toggle.checked) { 
      console.info("Unsubscribe the user from push notifications");
      this.onesignalService.unSubscribeFromNotifications().then(() => { 
        toggle.checked = this.onesignalService.isNotificationsEnabled;
        this._snackBar.open("Opted Out from push notifications", "Ok", {
          duration:3000,
        });
      });
       
    } else if (toggle.checked == false) {
      console.info("Enabling push notification");
      this.onesignalService.showNativePrompt().then(() => {
        if (Notification.permission === 'denied') {
             console.warn('Permission for Notifications was denied');
             this._snackBar.open("Permission for Notifications was denied. Please check your settings", "Ok");
             return;
         }

        console.log('Success  showing prompt and setting notification');
         toggle.checked = this.onesignalService.isNotificationsEnabled;
         this._snackBar.open("Success enabling Push Notifications", "Ok", {
          duration:3000,
        });
      }).catch( (e) => {
        console.log('fell in to the error clause');
        console.log(e);
      });
    }
  }

  get isNotificationsEnabled() {
    return this.onesignalService.isNotificationsEnabled
  }
}

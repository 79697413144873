<mat-tab-group animationDuration="200ms" (selectedIndexChange)="tabChanged($event)">
    <mat-tab label="All"></mat-tab>
    <mat-tab label="Rejected"></mat-tab>
</mat-tab-group>
  







import { Component } from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
@Component({
  selector: 'app-housekeeping',
  standalone: true,
  imports: [MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatTabsModule],
  templateUrl: './housekeeping.component.html',
  styleUrl: './housekeeping.component.scss'
})
export class HousekeepingComponent {

  tabChanged(event:any) {
    console.log(event)
  } 
}

import { Injectable } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  constructor(private oneSignal: OneSignal) {}

  initializeSDK() {
    this.oneSignal.init({
      appId: environment.oneSignalAppID,
      serviceWorkerParam: { scope: "/push/onesignal/" },
      serviceWorkerPath: "/push/onesignal/OneSignalSDKWorker.js",
      autoRegister: false, // Disable automatic prompt
      notifyButton: {
        enable: false
      }
    }).then(() => {
      console.log('Success initializing one signal for web use from the service');
    });
  }

  showSoftPrompt() {
    if (this.oneSignal.Notifications.permission == false) {
      console.log('Permissions was false asking user to consent');
      this.oneSignal.Slidedown.promptPush();
    } else {
      console.log('Permission already given re-subscribe');
    }
  }

  showNativePrompt():Promise<void> {
    if(this.oneSignal.Notifications.permission) {
      console.log('Already Opted in simply re-subscribe');
      return this.oneSignal.User.PushSubscription.optIn();
    } else {
      /// only show native prompt when no permision is granted
      console.log('User had not been prompted before ask now');
      return this.oneSignal.Notifications.requestPermission();
    }
   
  }

  unSubscribeFromNotifications():Promise<void> {
    if(this.oneSignal.User.PushSubscription.optedIn) {
      console.log('User is Opting out of push notifications');
      return this.oneSignal.User.PushSubscription.optOut();
    }

    return Promise.resolve();
  }

  get isNotificationsEnabled() {
    return this.oneSignal.Notifications.permission && this.oneSignal.User.PushSubscription.optedIn == true;
  }
  
}

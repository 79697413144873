<header>
    <mat-toolbar class="fixed top-0 toolbar border-bottom" >
      <span class="nav-title" (click)="homeClicked()" >{{ title }}</span>
      
      <span class="spacer"></span>
      <span>
        <input  class="collapse w-0" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </span>
      <mat-divider></mat-divider>
    </mat-toolbar>
  </header>
  <div class="router-container">
    <router-outlet ></router-outlet>
  </div>
<bottom-navigation (titleChangeEvent)="updateAppTitle($event)" class="bottom-navigation"></bottom-navigation>
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RouterOutlet } from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import { AngularSvgIconModule } from 'angular-svg-icon';

// Interface for link data
interface Link {
  label: string;
  icon: string;
  path?: string; // Optional path for navigation
}


@Component({
  selector: 'bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, CommonModule, RouterModule, MatDividerModule, AngularSvgIconModule],
})
export class NavigationComponent {
  @Output() titleChangeEvent = new EventEmitter<string>();
  activeIndex : number = 0;
  toolbarLinks: Link[] = [
    { label: 'Housekeeping', icon: 'assets/icons/housekeper-icon.svg' },
    { label: 'Maintenance', icon: 'assets/icons/maintenance-icon.svg' },
    { label: 'Found', icon: 'assets/icons/lost-and-found-icon.svg' },
    { label: 'Houseman', icon: 'assets/icons/houseman-icon.svg' },
    { label: 'More', icon: 'assets/icons/more-icon.svg' }
  ];

  constructor(private router: Router) {}

  onLinkClick(clickedIndex: number) {
    this.activeIndex = clickedIndex;
    const link:Link = this.toolbarLinks[clickedIndex];
    switch (link.label) {
      case 'Housekeeping':
        this.titleChangeEvent.emit('Housekeeping');
        this.router.navigate(['/housekeeping']);
        break;
      case 'Houseman':
        this.titleChangeEvent.emit('Houseman');
        this.router.navigate(['/houseman']);
        break;
      case 'Maintenance':
        this.titleChangeEvent.emit('Maintenance');
        this.router.navigate(['/']);
        break;
      case 'Found':
          this.titleChangeEvent.emit('Lost & Found');
          this.router.navigate(['/']);
          break;

        case 'More':
            this.titleChangeEvent.emit('Settings');
            this.router.navigate(['/settings']);
            break;
      default:
        this.titleChangeEvent.emit('Housekeeping');
        console.warn(link.label + ' not implemented');
        this.router.navigate(['/']);
        break;        
    }
   
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}

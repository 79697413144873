import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-houseman',
  standalone: true,
  imports: [AngularSvgIconModule],
  templateUrl: './houseman.component.html',
  styleUrl: './houseman.component.scss'
})
export class HousemanComponent {

}

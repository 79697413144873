<mat-toolbar class="fixed bottom-0 w-full border-top">
  <span class="sides-left"></span>
  <ng-container *ngFor="let item of toolbarLinks; let i = index">
  <button class="navigation-button mb-8" [ngClass]="{ 'active-link': i === activeIndex }" (click)="onLinkClick(i)">
    <svg-icon [src]="item.icon" [svgStyle]="{ 'width.px':40 }"  [ngClass]="{ 'active-icon': i === activeIndex, 'inactive-icon': i !== activeIndex }" ></svg-icon>
    <span class="button-text" [ngClass]="{ 'active-link': i === activeIndex }">{{ item.label }}</span>
  </button>
 <span class="spacer" *ngIf="i < toolbarLinks.length -1"></span>
  <span class="sides-right"></span>
</ng-container>
</mat-toolbar>

import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {NavigationComponent} from './bottom-navigation/bottom-navigation.component';
import { environment } from '../environments/environment';
import { HousemanComponent } from './houseman/houseman.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http';
import { SvgIconComponent, provideAngularSvgIcon } from 'angular-svg-icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    BrowserModule,
    MatListModule,
    MatTabsModule,
    MatSlideToggleModule,
    NavigationComponent,
    HousemanComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatToolbarModule, MatButtonModule, MatIconModule,
    AppRoutingModule,
    ServiceWorkerModule.register('hk-service-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    SvgIconComponent
  ],
  providers: [provideAngularSvgIcon()],
  bootstrap: [AppComponent]
})
export class AppModule {
 
 }

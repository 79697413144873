


            <p class="">This is the first line</p>
            <p class="">Back to lazy</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <svg-icon src="assets/icons/houseman-icon.svg" [svgStyle]="{ 'width.px':45, 'fill':'red' }"></svg-icon>

            <p>Camara in the atribute</p>
            <input type="file" accept="image/*;capture=camera">



            <br>
            <br>
            <br>
            <p>Environment in the attribute</p>
            <input type="file" accept="image/*" capture="environment" />
            <br>
            <br>
            <br>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>

            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>

            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>

            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>

            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Friends are good and not food!</p>
            <p class="">Ultima linea</p>

       



<section>
  <p> Theme </p>
  <mat-selection-list [multiple]="false">
      @for (theme of themeOptions; track theme) {
        <mat-list-option [value]="theme" (click)="setTheme(theme)"  [selected]="theme.toLowerCase() == selectedTheme">{{theme}}</mat-list-option>
      }
  </mat-selection-list>
</section>
<mat-divider></mat-divider>
<section>
  <br>
  <p> Notifications </p>
  <div class="settings-row">
    <span class="row-text">Enable Push Notifications</span>
    <mat-slide-toggle #toggle [checked]="isNotificationsEnabled" (click)="onNotificationToogleClicked($event, toggle)"></mat-slide-toggle>
  </div>

</section>


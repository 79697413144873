import { Component, isDevMode, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { slideInAnimation } from './app-routing.animations';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';
import { OnesignalService } from './onesignal.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]  
})
export class AppComponent implements OnInit{
  title = environment.title;
  logo = environment.logo
  get styles() {
    return {
        'fontSize': '1.6em',
        'background-color': environment.themeColor
    }
  }


  
  constructor( private onesignalService:OnesignalService, private settingsService: SettingsService, private router: Router) {
    onesignalService.initializeSDK();
  }

  public onRegister() {
    console.log('User chose to register');
  }

  homeClicked() {
    this.router.navigate(['/']);
  }

  ngOnInit():void {
    this.settingsService.applyTheme();
    this.router.navigate(['housekeeping']);
  }

  updateAppTitle(value:string) {
    this.title = value
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor() {
    /// listem for changes in the system color prefferences
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      this.applyTheme();
      console.info("Responding to system color change event");
  });
  }

  updateTheme(theme:string) {
    localStorage.setItem('theme', theme);
    this.applyTheme();
  }

  get currentTheme() {
    let theme = localStorage.getItem('theme')?.toLowerCase() 
    if(theme == null) {
      theme = 'system';
      localStorage.setItem('theme', theme);
      console.info('defaulting to system preference for theme');
    }

    return theme;
  }

  isCurrentTheme(theme:string) {
     return theme.toLowerCase() === this.currentTheme;
  }

  applyTheme() {
    switch (this.currentTheme) {
      case 'light':
        document.documentElement.removeAttribute('theme');
        break;
      case 'dark':
        document.documentElement.setAttribute('theme', "dark");
          break;
      case 'system':
            const isDark:boolean = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if(isDark){
              document.documentElement.setAttribute('theme', "dark");
            } else {
              // is light mode
              document.documentElement.removeAttribute('theme');
            }
            break;
    }
  }
}


